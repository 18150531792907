import React from "react"
import {
  NotFoundSplitWithImage as NotFound,
} from "@domenicosolazzo/site-designsystem"
import Layout from "../components/Layouts/Layout"
import { Error404, DataLinks} from "../data/index"

export default function NotFoundPage() {
    return(
        <Layout>
            <NotFound {...Error404}/>
        </Layout>
    )
}